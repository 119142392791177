@import './main.scss';

.card-att-div{
    margin-top: 0px;
   
    width: 100%;
    background-image: url(../img/IMG_8964.JPG);
    min-height: 300px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 100px;

}

.att-con {
 
  padding-top: 100px;
}

.att-waves{
    width: 100%;
 

   
}