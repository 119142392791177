@import './main.scss';

.team-div {
    background-color: $secondary-main;
    background-image: url(../img/curved1.svg);
    background-repeat: no-repeat;
    background-size: 100% 100% !important;
    width: 100%;
    padding-bottom: 100px;
    margin-bottom: 50px;
    Box-shadow: 4px 6px 20px black;
}
.story-con {
    background-color: white;
}

.team-title-row {
    padding-top: 50px;
    text-align: center;
    color: white;
}

