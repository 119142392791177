@import './main.scss';


.service-container {
    margin-top: 50px;
    padding-bottom: 50px;
    
}

.service-title-row {
    padding: 30px;
    font-weight: 600;
    text-shadow: 1px 1px $shadow-dark;
    text-align: center;
    color: white;
    background-size: 100% 100% !important;
    background-image: url(../img/wavesOpacity.svg);
    
}

.service-div {

    width: 100%;
    background-color: $secondary-main;
    background-image: url(../img/wavesOpacity.svg);
    background-repeat: no-repeat;
    margin-bottom: 50px;
    box-shadow: 4px 6px 15px $shadow-dark;
}

.custom-shape-divider-top-1651718085 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}



.service-icon {
    color: black;
}
.custom-shape-divider-bottom-1651728767 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1651728767 svg {
    position: relative;
    display: block;
    width: calc(149% + 1.3px);
    height: 172px;
}

.custom-shape-divider-bottom-1651728767 .shape-fill {
    fill: #464646;
}

.svg-row{
    width: 100%;
}

.service-title-h1 {
    margin-top: 50px;
    background-image: url(../img/brush.svg);
    width: 100%;
}