@import './main.scss';

.footer-div {
    width: 100%;
    background-color: $secondary-main;
    padding-top: 50px;
    margin-top: 50px;
}

.lupi-location {
    border: none;
    box-shadow: 4px 6px 15px black;
    border-radius: 5px;
}