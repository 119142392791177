@import './main.scss';


.story-div {
    width: 100%;
    background-color: $primary-main;
    margin-top: 50px;
    margin-bottom: 50px;
    box-shadow: 4px 6px 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    
    

}

.story-row-main {
    padding-top: 0px;
}

.story-con {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: lighten($color: #000000, $amount: 15%);
    border-radius: 50px;
    box-shadow: 4px 3px 15p;
    background-position: center;
    color: white;   
}

.lupi-work-vid {
    width: 400px;
    height:200px;
    box-shadow: 1px 1px 12px;
}
