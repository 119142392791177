@import './main.scss';

.card-div{
    background-color: black;
    width: 100%;
}

.card-con {
    margin-top: 50px;
    margin-bottom: 50px;
    padding-top: 25px;
    padding-bottom: 25px;

}

.logo-att {
    width: 150px;
    transform: scale(1.4);

}

.avatar-pic {
    width: 150px;
}

.logo-background {
    background-image: url(../img/LupiLogov2.png);
    background-position: center;
    background-size: contain;

}