$primary-main: #424242;
$primary-dark: #1F1F1F1F;
$primary-light: #717171;
$nav-bar: #424242bc;
$secondary-main: #ff9718;
$secondary-light:#ffc850 ;
$secondary-dark: #c66800;
$shadow-dark: rgb(60, 60, 60);
$shadow-light: #686868;





a {
 outline: none;
 text-decoration: none;
 padding-top: 8px;
 padding-left: 10px;
 padding-right: 10px;
 color: white;
 transition: all .5s ease-in-out;
 &:hover { 
 
     color:$secondary-main;
     transform: scale(1.2)
 }
 :link {
     color:$secondary-main;
 }
}




