@import './main.scss';

.navbar-main {
    background-image: url(../img/waves.svg);
    background-color:rgba(0, 0, 0, 0.946);
    background-size: 100% 100% !important;
    border-bottom: .5px solid black;
    box-shadow: 4px 6px 15px $shadow-dark;
  
}

.logo-navbar{
    transition: all .5s ease-in-out;
    transform: scale(1.1);
    &:hover {
        transform: scale(1.2);
    }
}

.nav-link {
    
    color: white !important;
    text-decoration: none;
    transition: all .5s ease-in-out;
    &:hover {
        color: $secondary-main !important;
        transform: scale(1.2);
    }
}

.nav-move {
    margin-right: 50px;
}

.icon {
    color: $secondary-main !important;
    transition: all .5s ease-in-out;
    &:hover {
        color: white !important;
        transform: scale(1.2);
    }
}
